import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'Inscricao',
    path: PATHS.INSCRICAO,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/inscricao/Inscricao.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
  },
  {
    name: 'Administration',
    path: PATHS.ADM,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/administration/Administration.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'AdministrationUsers',
        path: PATHS.ADM_USERS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/users/Users.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01',
        },
      },
      {
        name: 'AdministrationTimes',
        path: PATHS.ADM_TIMES,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/times/Times.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === 'Auth') {
    next({ name: 'Home' })
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn && !localStorage.getItem('token')) {
      next({ name: 'Auth' })
    } else {
      if (to.meta.permission !== undefined) {
        if (!store.getters.getModuleUserByCode(to.meta.permission)) {
          next({ name: 'Unauthorized' })
        }
      }
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
