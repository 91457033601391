const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_USERS = `${ADM}/users`
const ADM_TIMES = `${ADM}/times`
const INSCRICAO = `${BASE_URL}Inscricao`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_USERS,
  ADM_TIMES,
  UNAUTHORIZED,
  INSCRICAO,
}
