import auth from './modules/auth/auth'
import layouts from './modules/layout/layouts'
import users from './modules/user/users'
import modules from './modules/module/modules'
import inscricao from './modules/inscricao/inscricao'
import times from './modules/times/times'

export default {
  auth,
  layouts,
  users,
  modules,
  inscricao,
  times,
}
