import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getTimes({ commit }) {
      try {
        const response = await api.get('/times')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTime({ commit }, timeId) {
      try {
        const response = await api.get(`/times/${timeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateTime({ commit }, time) {
      try {
        return await api.put(`/times/${time.id}`, time)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
