import api from '@/services/api'
import store from '../..'

export default {
  state: {},

  mutations: {},

  actions: {
    async getUsers({ commit }) {
      try {
        const response = await api.get('/users')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getUser({ commit }) {
      try {
        const userId = store.getters.user.id
        const response = await api.get(`/users/${userId}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUser({ commit }, user) {
      try {
        return await api.put(`/users/${store.getters.user.id}`, user)
      } catch (error) {
        throw error
      }
    },

    async updatePassword({ commit }, data) {
      try {
        return await api.put(`/users/${data.userId}/password`, data)
      } catch (error) {
        throw error
      }
    },

    async getUserModules({ commit }, userId) {
      try {
        const response = await api.get(`users/${userId}/modules`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUserModules({ commit }, data) {
      try {
        return await api.put(`users/${data.userId}/modules`, data.modules)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
