<template>
  <div>
    <div>
      <div class="row no-gutters">
        <div class="col-lg-8">
          <div class="authentication-bg"></div>
        </div>
        <div class="col-lg-4" style="background-color: #dee7ec">
          <div
            class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
          >
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div class="row justify-content-center">
                        <div class="col-lg-6">
                          <span class="logo">
                            <img
                              src="@/assets/images/logo/logo-dark.png"
                              height="60"
                              alt="logo"
                            />
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <span class="logo">
                            <img
                              src="@/assets/images/logo/logo-arena.png"
                              height="80"
                              alt="logo"
                            />
                          </span>
                        </div>
                      </div>

                      <h4 class="font-size-18 mt-4">Torneio de Volei</h4>
                      <p class="text-muted">
                        Faça login para entrar no sistema
                      </p>
                    </div>
                    <div class="p-2 mt-5">
                      <form class="form-horizontal" @submit.prevent="auth">
                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-mail-line auti-custom-input-icon" />
                          <label for="email">E-mail</label>
                          <input
                            type="email"
                            id="email"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.email.$error,
                            }"
                            placeholder="seuemail@exemplo.com.br"
                            v-model="$v.email.$model"
                          />
                          <div v-if="$v.email.$error" class="invalid-feedback">
                            <span v-if="!$v.email.required">
                              O e-mail é obrigatório
                            </span>
                            <span v-if="!$v.email.email">
                              Por favor, insira um email válido
                            </span>
                          </div>
                        </div>
                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-lock-2-line auti-custom-input-icon" />
                          <label for="password">Senha</label>
                          <input
                            type="password"
                            id="password"
                            class="form-control"
                            :class="{ 'is-invalid': $v.password.$error }"
                            placeholder="Sua senha"
                            v-model="$v.password.$model"
                          />
                          <div
                            v-if="!$v.password.minLength"
                            class="invalid-feedback"
                          >
                            A senha deve ter pelo menos
                            {{ $v.password.$params.minLength.min }} caracteres
                          </div>
                        </div>
                        <div class="mt-4 text-center">
                          <button
                            type="submit"
                            class="btn btn-danger w-md waves-effect waves-light"
                            title="Login"
                            :disabled="$v.$invalid || disableButton"
                          >
                            <i
                              v-if="disableButton"
                              class="spinner-grow spinner-grow-custom text-wither"
                            />
                            <span v-else>Login</span>
                          </button>
                        </div>
                        <div class="mt-4 text-center">
                          <router-link
                            to="/password-recovery"
                            class="text-muted"
                            title="Esqueceu sua senha?"
                          >
                            <i class="mdi mdi-lock mr-1" /> Esqueceu sua senha?
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',

  data() {
    return {
      email: '',
      password: '',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('auth', {
            email: this.email,
            password: this.password,
          })

          this.disableButton = false
          this.$router.push(PATHS.HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                toaster: 'b-toaster-top-center',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },

  created() {
    if (localStorage.getItem('sessionError')) {
      this.$root.$bvToast.toast(localStorage.getItem('sessionError'), {
        title: 'Atenção!',
        toaster: 'b-toaster-top-center',
        variant: 'warning',
        solid: true,
      })
      localStorage.removeItem('sessionError')
    }
  },
}
</script>
