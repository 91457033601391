export default {
  state: {
    layoutType: 'horizontal',
    layoutWidth: 'fluid',
    topbarMode: 'light',
  },

  mutations: {
    CHANGE_LAYOUT(state, layoutType) {
      state.layoutType = layoutType
    },

    CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
      state.layoutWidth = layoutWidth
    },

    CHANGE_TOPBAR_MODE(state, topbarMode) {
      state.topbarMode = topbarMode
    },
  },

  actions: {
    changeLayoutType({ commit }, { layoutType }) {
      commit('CHANGE_LAYOUT', layoutType)
    },

    changeLayoutWidth({ commit }, { layoutWidth }) {
      commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
    },

    changeTopbarMode({ commit }, { topbarMode }) {
      commit('CHANGE_TOPBAR_MODE', topbarMode)
    },
  },

  getters: {
    layoutType: (state) => state.layoutType,
    layoutWidth: (state) => state.layoutWidth,
    topbarMode: (state) => state.topbarMode,
  },
}
