import api from '@/services/api'

export default {
  state: {
    user: {
      id: 0,
      name: '',
      modules: null,
    },
  },

  mutations: {},

  actions: {
    async saveTime({ commit }, data) {
      try {
        return await api.post('/inscricao', data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
